import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPropsStore,
	IPageWillMountHandler,
	Props,
	CompEventsRegistrarSym,
	ICompEventsRegistrar,
} from '@wix/thunderbolt-symbols'
import { INavigation, NavigationSymbol } from 'feature-navigation'
import { ComboboxinputNavigationPageConfig } from './types'
import { name } from './symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { SiteMembersApiSymbol, ISiteMembersApi, MemberDetails } from 'feature-site-members'
import { ComboBoxInputNavigationOption, ComboBoxInputNavigationProps } from '@wix/thunderbolt-components'

const getRelativeUrlFromOption = (option: ComboBoxInputNavigationOption) =>
	option.link?.href || option.link?.linkPopupId || option.link?.anchorDataId || option.link?.anchorCompId || null

/**
 * This is your feature.
 * You can get your configuration written in site-assets and viewer-model injected into your feature
 */
const comboboxinputNavigationFactory = (
	pageFeatureConfig: ComboboxinputNavigationPageConfig,
	propsStore: IPropsStore,
	compEventsRegistrar: ICompEventsRegistrar,
	navigation: INavigation,
	urlHistoryManager: IUrlHistoryManager,
	siteMembersApi?: ISiteMembersApi
): IPageWillMountHandler => {
	return {
		async pageWillMount() {
			const { componentIds } = pageFeatureConfig
			const relativeUrl = urlHistoryManager.getRelativeUrl()
			const memberInfo = siteMembersApi ? await siteMembersApi.getMemberDetails() : {}
			const memberSlug = memberInfo ? (memberInfo as MemberDetails).slug : ''
			const resolvedRelativeUrl = memberSlug ? relativeUrl.replace(memberSlug, '{userName}') : relativeUrl

			const updatedProps = componentIds.reduce((acc, compId) => {
				const compProps: ComboBoxInputNavigationProps = propsStore.get(compId)
				const compOptions: Array<ComboBoxInputNavigationOption> = compProps.options
				const currentOption = compOptions
					? compOptions.find((option) => getRelativeUrlFromOption(option) === resolvedRelativeUrl)
					: null

				compEventsRegistrar.register(compId, 'onChange', (event: any = {}) => {
					const value = event.target?.value ?? ''
					const eventCompProps: ComboBoxInputNavigationProps = propsStore.get(compId)
					const options: Array<ComboBoxInputNavigationOption> = eventCompProps.options
					const selectedOption = options ? options.find((option) => option.value === value) : null

					if (selectedOption && selectedOption.link) {
						navigation.navigateTo(selectedOption.link)
					}
				})

				return {
					...acc,
					[compId]: {
						value: currentOption ? currentOption.value : '',
					},
				}
			}, {})

			propsStore.update(updatedProps)
		},
	}
}

export const ComboboxinputNavigation = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		Props,
		CompEventsRegistrarSym,
		NavigationSymbol,
		UrlHistoryManagerSymbol,
		optional(SiteMembersApiSymbol),
	],
	comboboxinputNavigationFactory
)
